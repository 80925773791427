<template>
  <div class="aboutUs">
    <div class="aboutUs-cont">
      <img src="../../assets/img/zhxt-logo.png" alt="">
      <p>智汇学堂，由广州市传知汇科技有限公司（以下简称传知汇）研发，致力于为教育行业提供基于SaaS模式的互联网教育平台和解决方案，并将矢志不渝的以建立共赢良性的互联网教育产业生态环境作为公司的发展目标。</p>
      <p>传知汇的系列教育产品和服务充分结合“现代教育理念和教育技术”，运用云存储与计算、开放引擎、移动应用、流媒体等技术服务，从“资源+内容+教学服务”三方面着手，打造贯穿“招生、教、学、管、考、评、分享、大数据、运营”环节的核心O2O业务线，并结合不同应用对象的特点提供“个性化、拓展性”的深度解决方案。</p>
      <p>未来，我们将不断地向着打造规范的教育培训品牌目标迈进。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'aboutUs',
}
</script>

<style lang='scss'>
  .aboutUs {
    height: 100%;
    box-sizing: border-box;
    padding: 40px 24px 0;
    .aboutUs-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > img {
        width: 140px;
        height: 37px;
        margin-bottom: 24px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        text-indent: 32px;
        line-height: 30px;
      }
    }
  }
</style>
